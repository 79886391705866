import React from "react";

const Header = () => {
  return (
    <div className="title-wrapper">
      <p className="title">ELIMINACIÓN DE LA CUENTA</p>
      <div className="cloudy"/>
    </div>
  )
}

export default Header;