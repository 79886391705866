import React from "react";
import Menu from "../../Menu";
import {menuData} from "../../../utils/constants";
import { useNavigate } from 'react-router-dom';

import './index.scss';
import {layoutData} from "../constants";

interface IProps {
  children?: any
}

const Layout = (props: IProps) => {
  const navigate = useNavigate();

  const onChange = (val: any) => {
    navigate(val)
  }

  return (
    <div className="pmp-layout-pc">
      <div className="header">
        <div className="logo-wrapper">
          <div className="logo"/>
          <span className="text">{layoutData.title}</span>
        </div>
        <Menu dataSource={menuData} onChange={onChange} />
      </div>
      {props.children}
      <div className="footer">
        <div className="inner">
          <div className="left">
            <p className="title">{layoutData.title}</p>
            <p className="desc">{layoutData.desc}</p>
          </div>
          <div className="right">
            {
              layoutData.list.map(item => (
                <div className="list" key={item.icon}>
                  <div className={`icon ${item.icon}`}/>
                  <span>{item.title}</span>
                </div>
              ))
            }
          </div>
        </div>
        <p className="copy-right">{layoutData.copyright}</p>
      </div>
    </div>
  )

}

export default Layout;
