import axios from "axios";
export const APP_LINK : string = 'https://cl-loan-public-r-w.obs.la-south-2.myhuaweicloud.com/apk/ClzDine.apk ';
const SYS_CODE = 'ClzDine';

export enum eventCode {
  WB_1001 = 'WB_1001', // 浏览官网
  WCK_1001 = 'WCK_1001', // 官网点击下载APK,位1
  // WCK_1002 = 'WCK_1002', // 官网点击下载APK,位2
}

const isProduct: boolean = window.location.host === 'www.clzdine.com';
const devUrl: string = 'https://testapi.solpe.pe';
const proUrl: string = 'https://gw.clzdine.com';
const appId: string = '/31ab93970ba2187';
const url = isProduct ? proUrl + appId : devUrl + appId;

export const sendHomePv = () => {
  // 当eventCode = WB_1001 时， eventValue 取值为官网地址全路径
  // 当eventCode = WCK_1001 \ WCK_1002 时， eventValue 取值为下载链接值
  axios.post(url, {
    eventCode: eventCode.WB_1001,
    eventValue: window.location.href,
    pageUrl: window.location.href,
    platform: "h5",
    sysCode: SYS_CODE
  }).catch(() => {});
}

export const sendClickEvent = (eventCode: eventCode) => {
  axios.post(url, {
    eventCode,
    eventValue: APP_LINK,
    pageUrl: window.location.href,
    platform: "h5",
    sysCode: SYS_CODE
  }).catch(() => {});
}
