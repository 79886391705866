import React, {useEffect} from "react";

import './index.scss';
import {firstData, secondData, thirdData} from "../constants";
import {APP_LINK, eventCode, sendClickEvent, sendHomePv} from "../../../service";

const Home = () => {
  useEffect(() => {
    sendHomePv()
  }, []);
  const onClick = () => {
    sendClickEvent(eventCode.WCK_1001)
  }
  return (
    <div className="pmp-home-container-h5">
      <div className="first">
        <p className="title">{firstData.title}</p>
        <p className="sub-title">{firstData.subTitle}</p>
        <div className="imgs">
          {/*<div className="icon google-play"/>*/}
          <a
            className="download-btn"
            href={APP_LINK}
            target="_blank"
            rel="noreferrer"
            onClick={onClick}
          >Descargar</a>
          <div className="icon banner-img"/>
        </div>
      </div>
      <div className="second">
        <p className="title">{secondData.title}</p>
        <p className="sub-title">{secondData.subTitle}</p>
        {
          secondData.list.map(item => (
            <div className="card" key={item.icon}>
              <div className="card-header">
                <div className={`icon ${item.icon}`}/>
                <p className="card-title">{item.title}</p>
              </div>
              <p className="card-content">{item.content}</p>
            </div>
          ))
        }
      </div>
      <div className="third">
        <p className="title">{thirdData.title}</p>
        {
          thirdData.list.map((item, idx) => {
            if (item.title) {
              return (
                <div key={item.icon} className={`icons icons-${idx}`}>
                  <div className={`icon ${item.icon}`}/>
                  <p className="icon-title">{item.title}</p>
                </div>
              )
            }
            return <div className={`icon-arrow ${item.icon}`} key={item.icon}/>
          })
        }
      </div>
      <div className="cloudy"/>
    </div>
  )
}

export default Home;
