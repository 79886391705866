import PCHome from "../pages/Home/pc";
import H5Home from "../pages/Home/h5";
import NotFound from "../pages/NotFound";
import PCPolitica from '../pages/Politica/pc';
import H5Politica from '../pages/Politica/h5';
import PCEliminacion from '../pages/Eliminacion/pc';
import H5Eliminacion from '../pages/Eliminacion/h5';

interface IRoutes {
  path: string,
  element?: any,
}

const routes: IRoutes[] = [
  {
    path: '*',
    element: NotFound,
  },
  {
    path: '/',
    element: isMobile ? H5Home : PCHome
  },
  {
    path: '/politica',
    element: isMobile ? H5Politica : PCPolitica
  },
  {
    path: '/eliminacion',
    element: isMobile ? H5Eliminacion : PCEliminacion
  }
]

export default routes;
