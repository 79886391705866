import React, { useState } from "react";
import {Button, Input, Toast} from "antd-mobile";

const Feedback = () => {
  const [val, setVal] = useState("");
  const [loading, setLoading] = useState(false);

  const onClick = () => {
    if (val) {
      setLoading(true);
      // fake fetch
      setTimeout(() => {
        Toast.show({
          content: "Enviado exitosamente"
        });
        setLoading(false);
      }, 500)
    }
  }

  return (
    <div className="feedback">
      <p className="title">Verifica tu identidad</p>
      <p className="tips">Número de cuenta (número de teléfono móvil)</p>
      <Input className="fb-input" placeholder="Por favor escribe" onChange={setVal}/>
      <Button className="fb-btn" onClick={onClick} loading={loading}>Siguiente</Button>
    </div>
  )
}

export default Feedback;
