import React from "react";
import {CapsuleTabs} from "antd-mobile";
import { useLocation } from 'react-router-dom';

import './index.scss';

interface IProps {
  dataSource: any[]
  onChange?: (args?: any) => void
  className?: string
}

const Menu = (props: IProps) => {
  const { dataSource, className = "", ...others } = props;
  const { pathname } = useLocation();

  return (
    <CapsuleTabs
      className={`pmp-menu-container ${className}`}
      defaultActiveKey={pathname}
      {...others}
    >
      {
        dataSource.map(item => (
          <CapsuleTabs.Tab title={item.title} key={item.key} />
        ))
      }
    </CapsuleTabs>
  )

}

export default Menu;