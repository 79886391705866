import React, {useEffect} from "react";
import './index.scss';
import {firstData, secondData, thirdData} from "../constants";
import {APP_LINK, eventCode, sendClickEvent, sendHomePv} from "../../../service";

const Home = () => {

  useEffect(() => {
    sendHomePv()
  }, []);

  const onClick = () => {
    sendClickEvent(eventCode.WCK_1001)
  }

  return (
    <div className="pmp-home-container-pc">
      <div className="first">
        <div className="content">
          <div>
            <p className="title">{firstData.title}</p>
            <p className="sub-title">{firstData.subTitle}</p>
            {/*<div className="google-play" />*/}
            <a
              className="download-btn"
              href={APP_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={onClick}
            >Descargar</a>
          </div>
          <div className="banner-img" />
        </div>
      </div>
      <div className="second">
        <div className="content">
          <p className="title">{secondData.title}</p>
          <p className="sub-title">{secondData.subTitle}</p>
          <div className="card-wrapper">
            {
              secondData.list.map(item => (
                <div className="card" key={item.icon}>
                  <div className={`icon ${item.icon}`} />
                  <div className="card-text">
                    <p className="card-title">{item.title}</p>
                    <p className="card-content">{item.content}</p>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
      <div className="third">
        <div className="content">
          <p className="title">{thirdData.title}</p>
          <div className="icon-wrapper">
            {
              thirdData.list.map(item => {
                if (item.title) {
                  return (
                    <div key={item.icon} className="icons">
                      <div className={`icon ${item.icon}`} />
                      <p className="icon-title">{item.title}</p>
                    </div>
                  )
                }
                return <div className={`icon ${item.icon}`} key={item.icon}/>
              })
            }
          </div>
        </div>
        <div className="cloudy" />
      </div>
    </div>
  )
}

export default Home;
