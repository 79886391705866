export const firstData = {
  title: "Obtén $ 1.500.000 rápido",
  subTitle: "Obtenga respuestas instantáneas a sus necesidades de efectivo."
}

export const secondData = {
  title: "Nuestra ventaja",
  subTitle: "¡Bienvenido/a a nuestra aplicación de préstamos en efectivo! Nos comprometemos a ofrecerle un servicio conveniente y rápido de préstamos en efectivo de pequeñas cantidades.",
  list: [
    {
      title: "Experiencia de préstamo simple y rápida",
      content: "Proporcionamos una experiencia de préstamo simple y rápida para ayudarlo/a a satisfacer sus necesidades urgentes de dinero. Nuestro proceso de solicitud es sencillo y claro, permitiéndole obtener el efectivo que necesita de manera fácil y rápida.",
      icon: "clock"
    },
    {
      title: "Auditoría inteligente",
      content: "Nos esforzamos por brindar un servicio transparente y confiable. Nuestras tasas de interés y cargos se muestran claramente, sin costos ocultos, asegurando que comprenda claramente los costos del préstamo. Cumplimos estrictamente con las leyes y regulaciones para proteger la seguridad y privacidad de su información personal",
      icon: "lock"
    },
    {
      title: "Opciones flexibles de pago",
      content: "Para brindarle mayor flexibilidad, ofrecemos múltiples opciones de pago, para que pueda elegir la forma de pago que mejor se adapte a su situación. Nos comprometemos a ayudarlo/a a administrar fácilmente sus finanzas.",
      icon: "hand"
    },
    {
      title: "Soporte al cliente de alta calidad",
      content: "Nuestro equipo de atención al cliente está disponible para brindarle un servicio de alta calidad. Si tiene alguna pregunta, inquietud o necesita",
      icon: "group"
    }
  ]
}

export const thirdData = {
  title: "¿Cómo solicitar un préstamo?",
  list: [
    {
      title: "Descarga CLzDine desde Google Play Store",
      icon: "cloud"
    },
    {
      icon: "arrow1"
    },
    {
      title: "Completa el registro y verifica tu información",
      icon: "verify"
    },
    {
      icon: "arrow2"
    },
    {
      title: "Envía tu solicitud",
      icon: "note"
    },
    {
      icon: "arrow3"
    },
    {
      title: "Espera a que los fondos sean depositados en tu cuenta",
      icon: "cash"
    }
  ]
}
