export const layoutData = {
  title: "CLzDine",
  desc: "Las soluciones financieras están a su alcance.",
  copyright: "Copyright by CLzDine",
  list: [
    {
      icon: 'wa',
      title: "whatsapp: +56 986107228"
    },
    {
      icon: 'time',
      title: "Horario de atención: Lunes – Sábado 9:30-18:30"
    },
    {
      icon: 'email',
      title: "info.clzdine@gmail.com"
    },
    // {
    //   icon: 'address',
    //   title: "C JUAN ESCUTIA 513 COL BENITO JUAREZ 82180 MAZATLAN, SIN."
    // }
  ]
}
