import React from "react";

const Content = () => {
  return (
    <div className="content">
      <p className="abnormal fp">Si decide eliminar su cuenta, le proporcionaremos pasos sencillos. Tenga en cuenta que una
        vez que se elimine
        la cuenta, todos los datos asociados se borrarán de forma permanente y no se podrán recuperar. Si está seguro
        de que desea continuar con la eliminación de la cuenta, siga estos pasos:</p>
      <br/>
      <br/>
      <br/>
      <p>Ingrese el número de teléfono con el que se registró en esta aplicación.</p>
      <p>Ingrese a su cuenta</p>
      <p>obtener código de verificación</p>
      <p>Elige eliminar datos</p>
      <p>Confirmar la eliminación</p>
      <p>Realizar operación de eliminación</p>
      <p className="p-tag">Retírese con seguridad</p>
      <br/>
      <br/>
      <br/>
      <p className="abnormal lp">Asegúrese de haber leído atentamente los consejos y advertencias relevantes y de haber
        confirmado su decisión
        antes de realizar la operación de eliminación. Si tiene alguna pregunta o necesita ayuda, no dude en ponerse
        en contacto con nuestro equipo de atención al cliente.</p>
    </div>
  )
}

export default Content;