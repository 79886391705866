import React from "react";

const Content = () => {
  return (
    <div className="content">
      <p>Esta Política de privacidad describe las políticas y los procedimientos que sigue CLzDine en la recopilación,
        el uso y la divulgación de su información y otros datos cuando se registra para usar nuestras aplicaciones y
        servicios.</p>
      <p>Respetamos su privacidad y estamos comprometidos a proteger su privacidad al cumplir con esta política de
        privacidad ("Política").</p>
      <p>Esta política describe los tipos de información que podemos recopilar de usted o que usted puede proporcionar en
          la aplicación móvil "CLzDine" ("aplicación móvil" o "servicio") y cualquier producto y servicio relacionado
          ("información personal") ("Información personal") denominados colectivamente como "Servicios"), y nuestras
          prácticas de recopilación, uso, mantenimiento, protección y divulgación de esa información personal.</p>
      <p>También describe las opciones disponibles con respecto a nuestro uso de su información personal y cómo puede
          acceder a ella y actualizarla.</p>
      <p>Información personal del usuario</p>
      <p>Para realizar control de riesgo y evaluar la elegibilidad del usuario, necesitamos usar las informaciones
          personales incluido nombre completo, estado civil, género, fecha de nacimiento, edad, país, identificación oficial
          vigente, Instituto Nacional Electoral, correo electrónico.</p>
        <p>Se va realizar el depósito bancario directamente al número de cuenta proporcionados por los usuarios.</p>
      <p>Por qué usamos estos datos:</p>
      <p>Para procesamiento y autenticación de préstamos;</p>
      <p>Para habilitar la atención al cliente;</p>
      <p>Para investigación y desarrollo;</p>
      <p>Para las comunicaciones entre usted y nosotros;</p>
      <p>Para marketing y divulgación; Para la toma de decisiones automatizada;</p>
      <p>Para el cumplimiento y los requisitos legales.</p>
        <br/>
        <p>CÓMO UTILIZAMOS SUS DATOS PERSONALES Y PARA QUÉ FINES</p>
        <p>Solo utilizaremos sus datos personales de acuerdo con la ley aplicable. En la mayoría de los casos, utilizaremos
            sus datos personales para prestarle los Servicios, o cuando necesitemos cumplir con una obligación legal.</p>
      <p>Usted acepta y reconoce que al usar nuestros Servicios y crear una cuenta en la Aplicación, nos autoriza a
          nosotros y a nuestros socios asociados y afiliados a contactarlo por correo electrónico, teléfono o de otra
          manera. Esto es para garantizar que conozca todas las características de los Servicios.</p>
        <p>En general, utilizamos sus datos personales para los siguientes propósitos y actividades realizadas sin
            supervisión o control humano directo:</p>
        <p>Para registrarlo como usuario de la Aplicación;</p>
        <p>Para proporcionarle los Servicios;</p>
        <p>Para administrar nuestra relación con usted, incluida la notificación de cambios en cualquier Servicio;</p>
        <p>Para administrar y proteger nuestro negocio y la Aplicación, incluida la resolución de problemas, el análisis de
            datos, las pruebas del sistema y la realización de operaciones internas;</p>
        <p>Para proporcionarle contenido;</p>
      <p>Para monitorear tendencias para que podamos mejorar la Aplicación y los Servicios;</p>
      <p>Para mejorar nuestros modelos comerciales y de entrega;</p>
      <p>Para cumplir con nuestras obligaciones en virtud del acuerdo que estamos a punto de celebrar o hemos celebrado con
        usted;</p>
      <p>Para hacer cumplir nuestros Términos;</p>
      <p>Para responder a órdenes judiciales, establecer o ejercer nuestros derechos legales, o defenderse de reclamaciones
        legales.</p>
      <p>Contáctenos</p>
      <p>Si tiene alguna pregunta sobre nuestra política de privacidad o productos mientras navega por la Política de
        privacidad, contáctenos directamente.</p>
      <p>Correo electrónico: info.clzdine@gmail.com</p>
    </div>
  )
}

export default Content;
