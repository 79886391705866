import React from "react";
import Content from "../components/Content";
import Header from "../components/Header";

import './index.scss';

const Politica = () => {
  return (
    <div className="politica-container-h5">
      <Header />
      <Content />
    </div>
  )
}

export default Politica;