export const menuData = [
  {
    title: 'INICIO',
    key: '/'
  },
  {
    title: 'POLITICA DE PRIVACIDAD',
    key: '/politica'
  },
  {
    title: 'ELIMINACIÓN DE LA CUENTA',
    key: '/eliminacion'
  }
]
